import { render, staticRenderFns } from "./PaymentPaypal.vue?vue&type=template&id=840c9226&scoped=true&lang=pug"
import script from "./PaymentPaypal.vue?vue&type=script&lang=ts"
export * from "./PaymentPaypal.vue?vue&type=script&lang=ts"
import style0 from "./PaymentPaypal.vue?vue&type=style&index=0&id=840c9226&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "840c9226",
  null
  
)

export default component.exports
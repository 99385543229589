
  import { Component, Prop, Vue, Watch } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzBox from "@/components/Box.vue";
  import LzModal from "@/components/Modal.vue";
  import LzStepper from "@/components/Stepper.vue";
  import LzSubscription from "@/components/Subscription.vue";
  import { namespace } from "vuex-class";
  import { apiOrganizations } from "../api";
  import LzPaymentGateway from "./PaymentGateway.vue";

  const store = namespace("organization");
  const auth = namespace("auth");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  declare const ZFWidget: any;

  Component.registerHooks(["beforeRouteLeave"]);

  @Component({
    components: {
      LzButton,
      LzStepper,
      LzBox,
      LzPaymentGateway,
      LzModal,
      LzSubscription
    }
  })
  export default class Read extends Vue {
    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updatePlan!: (planId: string) => void;

    loaded = false;

    @auth.State("id")
    public memberId!: string;

    @auth.State("blockedBySubscriptionPlan")
    public blockedBySubscriptionPlan!: boolean;

    @store.State("planId")
    public planId!: string;

    @Prop({ default: "general" })
    step!: string;

    active = window.location.search.trim() ? "paymentGateway" : this.step;
    showBlockedModal = false;

    categoryOptions = {
      children: this.$t(
        "organization.read.generalForm.sector.options.children"
      ),
      education: this.$t(
        "organization.read.generalForm.sector.options.education"
      ),
      health: this.$t("organization.read.generalForm.sector.options.health"),
      animals: this.$t("organization.read.generalForm.sector.options.animals"),
      environment: this.$t(
        "organization.read.generalForm.sector.options.environment"
      ),
      naturalDisaster: this.$t(
        "organization.read.generalForm.sector.options.naturalDisaster"
      ),
      economicDev: this.$t(
        "organization.read.generalForm.sector.options.economicDev"
      ),
      other: this.$t("organization.read.generalForm.sector.options.other")
    };

    typeOptions = {
      member: this.$t("organization.read.generalForm.orgType.options.member"),
      fundation: this.$t(
        "organization.read.generalForm.orgType.options.foundation"
      ),
      company: this.$t("organization.read.generalForm.orgType.options.company"),
      other: this.$t("organization.read.generalForm.orgType.options.other")
    };

    payMethodOptions = {
      card: this.$t("organization.read.subscriptionForm.payMethod.options.card")
    };
    genderOptions = {
      man: this.$t("organization.read.generalForm.gender.options.man"),
      woman: this.$t("organization.read.generalForm.gender.options.woman"),
      other: this.$t("organization.read.generalForm.gender.options.other")
    };

    // generalForm
    generalForm: Partial<Member> = {
      firstName: "",
      lastName: "",
      address: "",
      gender: "",
      dni: "",
      mobilePhone: "",
      email: "",
      companyName: "",
      currency: "EUR",
      cif: ""
    };

    // organization
    organizationPlan = {
      id: "",
      PlatformSubscription: "",
      payment_type: ""
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    // suscription
    suscriptionForm = {
      payMethod: "",
      number: ""
    };

    SYMBOLS_CURRENCY: object = { EUR: "€", USD: "$" };
    currencyOptions: object = { EUR: "EUR", USD: "USD" };

    async mounted() {
      const { payment, planId } = this.$route.query;
      if (typeof payment === "string" && typeof planId === "string") {
        const sanitizedPlanId = planId.split(":")[1].trim();
        await this.onSubscriptionSubmit(sanitizedPlanId);
        this.active = "subscription";
      }

      await Promise.all([
        apiOrganizations.getOrganization(this.memberId).then(response => {
          const member = response;
          this.generalForm = {
            firstName: member.firstName,
            lastName: member.lastName,
            address: member.address,
            cif: member.cif,
            currency: member.currency,
            dni: member.dni,
            gender: member.gender,
            email: member.email,
            companyName: member.companyName,
            mobilePhone: member.mobilePhone
          };
        }),

        apiOrganizations.getOrganizationPlan(this.memberId).then(({ data }) => {
          this.organizationPlan.id = data?.id;
          this.organizationPlan.PlatformSubscription =
            data?.PlatformSubscription;
          this.organizationPlan.payment_type = data?.payment_type;
        })
      ]);

      this.loaded = true;
    }

    getSymbolCurrency(value: string): string {
      if (!value) {
        return "€";
      }

      return this.SYMBOLS_CURRENCY[value];
    }

    async membereneralSubmit() {
      this.generalForm.currencySymbol = this.getSymbolCurrency(
        this.generalForm.currency
      );
      await apiOrganizations
        .putOrganization(this.memberId, this.generalForm)
        .then(this.showSuccessNotification)
        .catch(this.showErrorNotification);
    }

    onSubscriptionSubmit(planId: string) {
      // eslint-disable-next-line no-console
    }

    showSuccessNotification() {
      this.$notify({
        type: "success",
        text: this.$tc("common.notifications.changeSuccess")
      });
    }
    showErrorNotification() {
      this.$notify({
        type: "error",
        text: this.$tc("common.error.generic")
      });
    }

    setSelectedPlan(plan: string) {
      this.organizationPlan.PlatformSubscription.id = plan;
      this.updatePlan(plan);
    }
    setPaymentType(type: string) {
      this.organizationPlan.payment_type = type;
    }

    beforeRouteLeave(_to: any, _from: any, next: any) {
      if (this.blockedBySubscriptionPlan) {
        return false;
      }
      next();
    }

    @Watch("blockedBySubscriptionPlan", { immediate: true })
    onBlockedBySubscriptionPlanChanged(newVal: boolean) {
      if (newVal) {
        this.showBlockedModal = true;
        this.active = "subscription";
      }
    }
  }


  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { namespace } from "vuex-class";
  import { apiWallet as wallet } from "../api";
  import PaymentCardView from "./PaymentCardView.vue";

  const auth = namespace("auth");

  @Component({
    components: { LzButton, PaymentCardView }
  })
  export default class PaymentGateway extends Vue {
    paymentMethodsCards = [
      {
        title: "stripe",
        features: [
          this.$t("organization.read.paymentCards.Stripe.features[0]"),
          this.$t("organization.read.paymentCards.Stripe.features[1]"),
          this.$t("organization.read.paymentCards.Stripe.features[2]"),
          this.$t("organization.read.paymentCards.Stripe.features[3]")
        ]
      }
    ];

    @auth.State("id")
    private memberId!: string;

    async changePaymentToStripe(clientId: string) {
      await wallet.stripe
        .updateClientId(this.memberId, clientId)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("organization.read.paymentGateway.stripe.connected")
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("organization.read.paymentGateway.stripe.error")
          });
        });
    }

    async mounted() {
      const clientId = ((this.$route.query.code || "") as string).trim();
      const isStripeConnected = !!clientId.length;

      if (isStripeConnected && this.memberId) {
        this.changePaymentToStripe(clientId);
      }
    }
  }

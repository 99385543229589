
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import LzLinkIcon from "@/components/LinkIcon.vue";
  import { namespace } from "vuex-class";
  import { apiWallet } from "../api";
  const auth = namespace("auth");

  @Component({
    components: { LzButton, LzModal, LzLinkIcon }
  })
  export default class StripePayment extends Vue {
    @auth.State("id")
    public ongId!: string;

    @Prop() paymentMethod!: PaymentMethod;

    @auth.State("stripe_account_id")
    public readonly stripeId!: string;

    @auth.Action("refreshMemberData")
    public refreshMemberData!: () => Promise<void>;

    enabled = false;
    connected = false;
    type: "account_onboarding" | "account_update" = "account_onboarding";
    chargesEnabled = false;
    payoutsEnabled = false;
    detailsSubmitted = false;

    async connect() {
      const { url } = await apiWallet.stripe.getConnectLink(
        this.ongId,
        this.type
      );
      window.open(url, "_blank");
    }

    async mounted() {
      await this.refreshMemberData();

      const { enabled, ...rest } = await apiWallet.stripe.getAccountStatus(
        this.ongId
      );

      if (enabled) {
        this.enabled = true;
        this.chargesEnabled = rest.charges_enabled;
        this.payoutsEnabled = rest.payouts_enabled;
        this.detailsSubmitted = rest.details_submitted;
        this.connected = rest.requirements.errors.length === 0;
        // this.type = "account_update"; // Error: Standard accounts can only update their details
      }
    }
  }
